/*
 * Created by IRIS Interactive
 * User : IRIS Interactive
 */

import BackToTopComponent from './components/back-to-top.component'
import NavbarComponent from './components/navbar.component'

document.addEventListener('DOMContentLoaded', () => {
    //Component
    new NavbarComponent()
    new BackToTopComponent()

    //Front page only
    if ($('.home').length) {
        import(
            '@scripts/services/home.service' /* webpackChunkName: "scripts/home.service" */
        ).then(({ default: HomeService }) => {
            new HomeService()
        })
    }

    //Fullscreen template only
    if ($('.fullscreen').length) {
        import(
            '@scripts/services/layout.service' /* webpackChunkName: "scripts/layout.service" */
        ).then(({ default: LayoutService }) => {
            new LayoutService()
        })
    }

    if (!$('.home').length && !!$('.form').length) {
        import(
            '@scripts/components/form-fields.component' /* webpackChunkName: "scripts/form-fields.component" */
        ).then(({ default: FormFieldsComponent }) => {
            new FormFieldsComponent()
        })
    }

    if ($('.customer-partners__header-list').length) {
        import(
            '@scripts/services/normans-directory.service' /* webpackChunkName: "scripts/normans-directory.service" */
        ).then(({ default: NormansDirectoryService }) => {
            new NormansDirectoryService()
        })
    }

    if ($('.customer-ambassadors__search-engine').length) {
        import(
            '@scripts/components/ambassadors-search-engine.component' /* webpackChunkName: "scripts/ambassadors-search-engine.component" */
        ).then(({ default: AmbassadorsSearchEngineComponent }) => {
            new AmbassadorsSearchEngineComponent()
        })
    }

    if ($('.customer-block-list').length) {
        import(
            '@scripts/components/customer-block-list.component' /* webpackChunkName: "scripts/customer-block-list.component" */
        ).then(({ default: CustomerBlockListComponent }) => {
            new CustomerBlockListComponent()
        })
    }
})
