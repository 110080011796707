export default class BackToTopComponent {
    constructor() {
        this.displayBackToTop()
        this.actionBackToTop()
    }

    static $backToTopSelector = $('.js-back-to-top')

    displayBackToTop() {
        function scrollDistance(distance = 2000, duration = 200) {
            if ($(window).scrollTop() > distance) {
                BackToTopComponent.$backToTopSelector.stop().fadeIn(duration)
            } else {
                BackToTopComponent.$backToTopSelector.stop().fadeOut(duration)
            }
        }

        $(window).on('load scroll', () => {
            scrollDistance()
        })

        scrollDistance()
    }

    actionBackToTop() {
        BackToTopComponent.$backToTopSelector.on('click', () => {
            $('html, body').animate(
                {
                    scrollTop: 0,
                },
                800,
            )
            return false
        })
    }
}
