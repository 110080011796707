export default class NavbarComponent {
    constructor() {
        NavbarComponent.sticky()
        NavbarComponent.burger()
        NavbarComponent.langSwitcher()
    }

    static sticky() {
        let lastScrollTop = 0

        $(window).on('load scroll', function () {
            const offset = 0 //Trigger offset (~header height)
            const navbar = $('[data-id="wpiris-navbar"]')
            const scrollTop = $(this).scrollTop()
            let full = false

            //Hidden comportment
            if (scrollTop >= lastScrollTop && scrollTop > navbar.outerHeight()) {
                $('body').addClass('header-is-hidden')
            } else {
                $('body').removeClass('header-is-hidden')
            }

            // Detect if header is on banner/video mode
            if ($('body.has-banner').length > 0) {
                full = true
            }

            lastScrollTop = scrollTop

            if (scrollTop > offset) {
                navbar.removeClass('navbar--ontop')
                navbar.addClass('navbar--onscroll')
                if (full === true) {
                    NavbarComponent.changeLogoColor('classic')
                }
            } else {
                navbar.removeClass('navbar--onscroll')
                navbar.addClass('navbar--ontop')
                if (full === true) {
                    NavbarComponent.changeLogoColor('banner')
                }
            }
        })
    }

    static changeLogoColor(color) {
        const logo = $('[data-id="wpiris-logo"]')
        let logoBanner
        let logoClassic

        if ($('html[lang*="en"]').length) {
            logoBanner = IRISCollectionCustomer.imageLogoBannerEn
            logoClassic = IRISCollectionCustomer.imageLogoEn
        } else {
            logoBanner = IRISCollectionCustomer.imageLogoBanner
            logoClassic = IRISCollectionCustomer.imageLogo
        }

        if (color === 'banner') {
            logo.attr('src', logoBanner)
        } else if (color === 'classic') {
            logo.attr('src', logoClassic)
        }
    }

    static burger() {
        const body = $('body')
        const trigger = '[data-trigger="wpiris-menu-toggle"]'
        const more = '[data-trigger="wpiris-menu-more"]'
        const prev = '[data-trigger="wpiris-menu-prev"]'
        const submenu = '[data-trigger="wpiris-menu-submenu"]'

        //Open/Close menu
        $(trigger).on('click', () => {
            body.toggleClass('menu-open')

            //Remove active class on submenu
            $(submenu).removeClass('submenu-open')
            body.removeClass('body-submenu-open')

            //Change logo on mobile
            if (window.matchMedia('(max-width: 1400px)').matches) {
                if (body.hasClass('menu-open') && $('.navbar--ontop').length) {
                    //Close ecobar
                    $('body').removeClass('eco-bar-open')
                    $('.eco-bar').addClass('eco-bar--hidden')
                }
            }
        })

        //Open/Close submenu
        $(more).on('click', function () {
            body.addClass('body-submenu-open')
            $(this).next(submenu).toggleClass('submenu-open')
        })

        $(prev).on('click', (e) => {
            e.preventDefault()
            $(submenu).removeClass('submenu-open')
            body.removeClass('body-submenu-open')
        })
    }

    static langSwitcher() {
        const $langSwitcher = $('.js-lang-switcher')
        const $langList = $('.js-lang-list')

        if ($langSwitcher.length > 0) {
            $langSwitcher.on('click', () => {
                $langList.toggleClass('is-visible')
            })
        }
    }
}
